import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import IntroArea from "@components/introarea/layout-1";
import BoxSectionOne from "@components/BoxSection/layout-one/layout-3";
import BoxSectionTwo from "@components/BoxSection/layout-two/layout-5";
import BoxSectionThree from "@components/BoxSection/layout-one/layout-2";
import HowWeWork from "@containers/global/how-we-work";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const EcommercePage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 20) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="E-commerce Localization Agency | Localization Services"
        description="Get your products and services to the people who need them with Andovar’s AI-powered e-commerce translation and localization solutions. Free Consultation."
        />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["ecommerce-translation-page-header"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) &&<>
            <FeatureGrid data={content["ecommerce-cast-features"]} />
            <IntroArea layout={2} data={content["ecommerce-intro"]} />
          </>
        }
        {
          showRestComponents && <>
         <BoxSectionOne
          layout={5}
          data={content["localize-ecommerce-solutions"]}
        />
        <BoxSectionThree
          layout={3}
          data={content["automation-solutions-body"]}
        />
        <BoxSectionTwo
          layout={5}
          data={content["keyfeatures-ecommerce-solutions"]}
        />
        <HowWeWork layout={2} data={content["howwework"]} /> 
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query ecommercePageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "ecommerce-translation-media" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

EcommercePage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default EcommercePage;
